/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import SectionTitleText from '@common/SectionTitleText'
import {
  ParagraphWrapper,
  SubHeaderWrapper,
} from '@common/CommonDisclamerPagesStyles'
import DisclamerPagesCommonContainer from '@containers/DisclamerPagesCommonContainer'

const IndexPage = ({ location }) => (
  <DisclamerPagesCommonContainer location={location}>
    <SectionTitleText text='Terms and Conditions' extraStyles='width:100%;' />
    <SubHeaderWrapper>Terms and Conditions&nbsp;</SubHeaderWrapper>
    <SubHeaderWrapper>§1 General</SubHeaderWrapper>
    <ParagraphWrapper>
      The following Terms and Conditions (T&amp;C) apply to all orders and
      purchases of OnlinePunks Reports from OnlinePunks GmbH.&nbsp;
    </ParagraphWrapper>
    <ParagraphWrapper>
      Divergent or additional conditions from the customer’s side are not valid,
      even if OnlinePunks GmbH was in knowledge of these conditions, unless
      there is a written agreement between the customer and OnlinePunks GmbH.
    </ParagraphWrapper>
    <SubHeaderWrapper>§2 Contract Formation</SubHeaderWrapper>
    <ParagraphWrapper>
      The conditions for all goods sold by OnlinePunks GmbH are non obligatory
      and non binding. Changes in fonts, colors and appearance are reserved if
      these changes are reasonable for the customers.
    </ParagraphWrapper>
    <ParagraphWrapper>
      The access to the OnlinePunks Reports takes place via an automatically
      generated link that is sent to the customer via email after the purchase.
      This link leads to a download page with the possibility to download the
      purchased reports. The customer is obligated to keep this link and any
      other information that provides access to the download page private and
      secure. A binding offer is made when the customer clicks the “Pay” button
      on the payment page that is provided by the payment platform “Chargebee”
      and these terms and conditions will apply. OnlinePunks GmbH will give
      access immediately after the payment is received and after there is a
      written payment confirmation sent. The customer agrees to these terms and
      conditions and confirms to be of legal age, when ordering from
      OnlinePunks. The customer agrees that all information provided in the
      checkout form is complete and correct. Any changes need to be communicated
      immediately to OnlinePunks GmbH.
    </ParagraphWrapper>
    <SubHeaderWrapper>§3 Provision of Goods</SubHeaderWrapper>
    <ParagraphWrapper>
      All OnlinePunks Reports are available in pdf format and are available as a
      download. To access these files an internet connection is mandatory, as
      well as the private link or a combination of the access code and email
      address of the customer.
    </ParagraphWrapper>
    <SubHeaderWrapper>§4 Prices</SubHeaderWrapper>
    <ParagraphWrapper>
      The offered price is binding. All prices are net and without any sales
      taxes, if not stated otherwise. Additional local taxes may be applicable.
    </ParagraphWrapper>
    <SubHeaderWrapper>§5 Payment Conditions</SubHeaderWrapper>
    <ParagraphWrapper>
      The sales price is due immediately and without any deductions as soon as
      the order is made.
    </ParagraphWrapper>
    Payment options are PayPal or credit card.&nbsp;
    <SubHeaderWrapper>§6 Distribution, Copyrights</SubHeaderWrapper>
    <ParagraphWrapper>
      All OnlinePunks Reports are subject to local copyrights.
    </ParagraphWrapper>
    <ParagraphWrapper>
      An unauthorized distribution or publication, either for free or
      commercially is prohibited if there is no written agreement between the
      customer and OnlinePunks GmbH.&nbsp;
    </ParagraphWrapper>
    <ParagraphWrapper>
      In case of any damages - direct or through lost sales - that are caused by
      unauthorized distribution, OnlinePunks GmbH reserves any rights to claim
      these damages from the perpetrator.&nbsp;
    </ParagraphWrapper>
    <SubHeaderWrapper>§7 Warranty</SubHeaderWrapper>
    <ParagraphWrapper>
      In case there are any deficiencies at the passage of risk, the customer is
      allowed to request supplementary performance through removal of defects.
      In case the removal of defects is not in proportion to the effort the
      customer may withdraw from the contract.
    </ParagraphWrapper>
    <SubHeaderWrapper>§8 Liability</SubHeaderWrapper>
    <ParagraphWrapper>
      In case of slightly negligent breaches of duty we shall be liable -
      irrespective of the legal ground therefore - only for the breach of
      material contractual obligations, and only for average damages typical to
      the contract and foreseeable in view of the nature of the goods, not for
      indirect damages such as lost profits.
    </ParagraphWrapper>
    <ParagraphWrapper>
      Every information provided in the products of OnlinePunks GmbH was created
      to the best of our knowledge and with great care and accuracy. Regardless,
      OnlinePunks GmbH is not responsible for any damages in any way that result
      in utilizing this information. Any provided information in the OnlinePunks
      Reports is just for educational purposes and may be incorrect or outdated.
      It is the sole responsibility of the customer and any user to carefully
      examine the information and techniques in the OnlinePunks Reports and read
      the terms and conditions and usage notes from any third-party platform
      that may be used. OnlinePunks GmbH is not connected or related to any
      business that is mentioned in the OnlinePunks Reports.&nbsp;
    </ParagraphWrapper>
    <SubHeaderWrapper>§9 Confidentiality and data protection</SubHeaderWrapper>
    <ParagraphWrapper>
      OnlinePunks GmbH is obliged to treat all knowledge which it receives from
      the customer on the basis of an order as strictly confidential for an
      unlimited period of time and to obligate both its employees and third
      parties engaged by it to absolute confidentiality in the same manner.
    </ParagraphWrapper>
    <ParagraphWrapper>
      OnlinePunks GmbH stores all data of the customer electronically during the
      duration of the contractual relationship, as far as this is necessary for
      the fulfillment of the contract purpose, in particular for accounting
      purposes. Onlinepunks also processes and uses the collected data
      anonymously for advising its customers, for advertising and market
      research for its own purposes and for the demand-oriented design of its
      telecommunications services. Onlinepunks will provide the customer at any
      time on request with complete and free information about the stored data
      stock, as far as he is concerned, and delete all stored personal data at
      the customer's request. The stored inventory data includes the personal
      data, namely name/company, profession, date of birth, company register
      number, powers of representation, contact person, business address and
      other addresses of the customer, telephone number, fax number, e-mail
      address, bank details and VAT number, as well as the access data ('login')
      of the company. Onlinepunks will neither forward this data nor the content
      of private messages from the customer to third parties without the
      customer's consent. This does not apply to the extent that Onlinepunks is
      obliged to disclose such data to third parties, in particular government
      agencies, or to the extent that internationally recognized technical
      standards provide for this and the customer does not disagree. Onlinepunks
      expressly indicates to the customer that data protection for data
      transmissions in open networks such as the Internet cannot be
      comprehensively guaranteed according to the current state of technology.
      The customer knows that the provider can view the website stored on the
      web server and, under certain circumstances, other customer data stored
      there at any time from a technical point of view. Other Internet users may
      also be technically able to interfere with network security without
      authorization and control message traffic. The customer is fully
      responsible for the security of the data transmitted by him to the
      Internet and stored on web servers.
    </ParagraphWrapper>
    <SubHeaderWrapper>§10 Final Regulations</SubHeaderWrapper>
    <ParagraphWrapper>
      Governing Law &amp; Jurisdiction. The validity, construction and
      performance of these Terms and Conditions (and any claim, dispute or
      matter arising under or in connection with it or its enforceability) shall
      be governed by and construed in accordance with the laws of Austria. We
      and you irrevocably submit to the exclusive jurisdiction of the Austria
      courts over any claim, dispute or matter arising under or in connection
      with this Agreement or its enforceability.
    </ParagraphWrapper>
    <ParagraphWrapper>
      The provisions of the UN Sales Convention (CISG) shall not apply. If
      individual regulations of the contract including these regulations should
      be totally or partly ineffective or should the contract a regulation gap
      contain, the effectiveness of the remaining regulations or parts of such
      regulations remains untouched.
    </ParagraphWrapper>
    <ParagraphWrapper> 01.02.2020</ParagraphWrapper>
  </DisclamerPagesCommonContainer>
)

IndexPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default IndexPage
